import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';


function importBuildTarget() {
if (process.env.REACT_APP_BUILD_TARGET === "403") {
    return import("./GeoRestrictedApp.js");
  } else {
    return import("./App.js");
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

importBuildTarget().then(({default: App}) => {
  root.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App/>
    </BrowserRouter>
  );
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
